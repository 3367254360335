import React from 'react';
import { Link } from "gatsby";
import { Menu } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'


export default class Antmobilemenu extends React.Component {
  handleClick = e => {
    console.log('click ', e);
  };

  render() {
    return (
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
      >
     <Menu.Item>
        <Link to="/">Home</Link>
        </Menu.Item>

        <Menu.Item><Link to="/about/">About us</Link></Menu.Item>
<Menu.Item><Link to="/products/">Products</Link></Menu.Item>
<Menu.Item><Link to="/solutions/">Solutions</Link></Menu.Item>
<Menu.Item><Link to="/services/">Services</Link></Menu.Item>
<Menu.Item><Link to="/expertise/">Expertise</Link></Menu.Item>
<Menu.Item><Link to="/csr/">CSR</Link></Menu.Item>
<Menu.Item><Link to="/newsevent/">News & Events</Link></Menu.Item>
<Menu.Item><Link to="/careers/">Careers</Link></Menu.Item>
<Menu.Item><Link to="/contacts/">Contacts</Link></Menu.Item>

        

      </Menu>
    );
  }
}