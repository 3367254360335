import React from 'react';
import { Link } from "gatsby";
import { Menu } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import HomeIcon from '@material-ui/icons/Home';
// import { DownOutlined } from '@ant-design/icons';

// const { SubMenu } = Menu;

export default function Antmenu() {

    const activemenu = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
      <Menu selectedKeys={[activemenu]} mode="horizontal"  style={{ borderBottom:'none', backgroundColor: 'transparent' }} className="menu">
                  <Menu.Item key="/"><Link to="/"><HomeIcon style={{ position:'relative', top:7 }}/></Link></Menu.Item> 
<Menu.Item key="/about/"><Link to="/about/">About us</Link></Menu.Item>
<Menu.Item key="/products/"><Link to="/products/">Products</Link></Menu.Item>
<Menu.Item key="/solutions/"><Link to="/solutions/">Solutions</Link></Menu.Item>
<Menu.Item key="/services/"><Link to="/services/">Services</Link></Menu.Item>
<Menu.Item key="/expertise/"><Link to="/expertise/">Expertise</Link></Menu.Item>
<Menu.Item key="/csr/"><Link to="/csr/">CSR</Link></Menu.Item>
<Menu.Item key="/newsevent/"><Link to="/newsevent/">News & Events</Link></Menu.Item>
<Menu.Item key="/careers/"><Link to="/careers/">Careers</Link></Menu.Item>
<Menu.Item key="/contacts/"><Link to="/contacts/">Contacts</Link></Menu.Item>

        

      </Menu>
    );
  }