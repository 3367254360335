import React from 'react';
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Toolbar, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import Antmenu from "./antmenu";
import Antmobilemenu from "./antmobilemenu";

import { StaticImage } from "gatsby-plugin-image"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      display:'none',
    },
  },
  appBar: {
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    // },
    backgroundColor:'#fff',
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
    color:'#000',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#001529',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

function Myheader(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
        <div>
    {/* <div className={classes.toolbar} /> */}
    <Divider />
    <List>
        <ListItem>
        <StaticImage
     src="../images/logo.png"
     alt="Africorp Logo"
     quality={95}
    placeholder="blurred"
     formats = {["auto", "webp", "avif"]}
     layout="fixed"
   />
        </ListItem>
    </List>
    <Antmobilemenu/>
  
  </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
            <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          </Hidden>
          <Box flexGrow={1}>
            <Link to="/">
          <StaticImage
     src="../images/logo.png"
     alt="Africorp"
     quality={95}
    placeholder="blurred"
     formats = {["auto", "webp", "avif"]}
     layout="fixed"
   />
   </Link>
   </Box>
<Hidden mdDown>
   <StaticImage
     src="../images/health.jpg"
     alt="Africorp"
     height={54}
     quality={95}
    placeholder="blurred"
     formats = {["auto", "webp", "avif"]}
     layout="fixed"
   />
   </Hidden>
        </Toolbar>

        <Hidden mdDown>
        <Toolbar style={{ backgroundColor:'#0e359d', minHeight:50, paddingRight:0 }}>
            <Box flexGrow={1}>
         <Antmenu/>
         </Box>

         <Box className='parallelogram' >
  <Box pt={1} pr={5}>
              <Link to="https://www.facebook.com/AfriCorpSupport/" target="_blank" rel="noopener noreferrer" style={{ marginRight:10, color:'#fff' }}><FacebookIcon/></Link>
              <Link to="https://www.linkedin.com/company/31057515" target="_blank" rel="noopener noreferrer" style={{ color:'#fff' }}><LinkedInIcon/></Link>
              </Box>      
          </Box>

        </Toolbar>
        </Hidden>

      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
      </main>
    </div>
  );
}

Myheader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Myheader;