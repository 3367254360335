/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import { Link } from "gatsby"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 
 import Header from "./header"
 import "./layout.css"
 import Bottomnav from "./bottomnav" // Tell Webpack this JS file uses this image

 
 import RoomIcon from '@material-ui/icons/Room';
 import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
 import DraftsIcon from '@material-ui/icons/Drafts';
 import { ListItem, List, Hidden, Box, Container, Typography, Grid } from '@material-ui/core';
 
 import Footerbg from "../images/footbg.jpg"

 
 import styled from "styled-components";
 
 const Bline = styled.hr`
 width: 70px;
 margin-bottom:15px;
 display:inline-block;
 border-color: #fd0100;
 `
 const White = styled.a`
 color:#fff;
 `
 
 const Footerback = {
   backgroundAttachment: 'fixed',
   backgroundImage: 'url('+ Footerbg+')'
   }
 
 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   return (
     <>
       <Header siteTitle={data.site.siteMetadata.title} />
       <div
       >
         <main>{children}</main>
         <Hidden mdUp>
         <Bottomnav/>
         </Hidden>
         <Hidden mdDown>
         <footer style={Footerback}>
         <div style={{ backgroundColor:'rgba(4, 32, 107, 0.79)', color:'#dedede' }}>
           <Box pt={3} pb={3}>
           <Container>
         <Grid container>
         <Grid item xs={12} sm={6} md={4}>
         <Box pr={2}>
         <Typography variant="h6" style={{ color:'#dedede' }}>
         About Company
         </Typography>
         <Bline/>
         <List>
           <Link to="/about/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">About Us</Typography></ListItem></Link>
           <Link to="/newsevent/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">News & Events</Typography></ListItem></Link>
           <Link to="/careers/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Careers</Typography></ListItem></Link>
           <Link to="/contacts/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Contacts</Typography></ListItem></Link>
         </List>
         </Box>
         </Grid>
 
         <Grid item xs={12} sm={6} md={4}>
         <Typography variant="h6" style={{ color:'#dedede' }}>
         Quick Links
         </Typography>
         <Bline/>
         <List>
           
           <Link to="/products/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Products</Typography></ListItem></Link>
           <Link to="/solutions/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Solutions</Typography></ListItem></Link>
           <Link to="/services/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Services</Typography></ListItem></Link>
           <Link to="/expertise/" style={{ color:'#dedede' }}><ListItem><Typography  gutterBottom variant="body1">Expertise</Typography></ListItem></Link>
 
         </List>
         </Grid>
 
         <Grid item xs={12} sm={6} md={4}>
         <Typography variant="h6" style={{ color:'#dedede' }}>
         Contact Us
         </Typography>
         <Bline/>
         <Typography  gutterBottom variant="body1"><RoomIcon style={{ color:'#47bdbf', position:'relative', top:7 }} />AFRICORP HEALTHCARE SYSTEMS LTD</Typography>
         <Box mb={2} pl={3}>
         <Typography  gutterBottom variant="body1">Lusaka - 10101, Zambia</Typography>
         </Box>
         <Box mb={2}>
         <Typography  gutterBottom variant="body1"><PhoneInTalkIcon style={{ color:'#47bdbf', position:'relative', top:7 }} /><White href="tel:+260953020081" target="_blank" rel="noopener noreferrer">+260 953 02 0081</White> </Typography>
         </Box>
         <Typography  gutterBottom variant="body1"><DraftsIcon style={{ color:'#47bdbf', position:'relative', top:7 }} /> <White href="mailto:info@africorp-healthcare.com" target="_blank" rel="noopener noreferrer">info@africorp-healthcare.com</White></Typography>
         </Grid>
 
 
           </Grid>
           </Container>
           </Box>
           </div>
        
 
 <div style={{ backgroundColor:'#06265d', color:'#dedede' }}>
           <Box pt={1} pb={1}>
           <Container>
         <Grid container>
         <Grid item xs={12} sm={6} md={6}>
         <Typography  gutterBottom variant="body1">
         Copyright © {new Date().getFullYear()} AfriCorp Healthcare Systems Ltd.
           {` `}
           All Rights Reserved.
           </Typography>
         </Grid>
 
         <Grid item xs={12} sm={6} md={6}>
         <Typography  gutterBottom variant="body1" align='right'> Designed & Developed by <a href="https://www.srkitsolutions.com/" rel="noopener noreferrer" target="_blank" style={{ color:'#00fbff' }}>SRK IT Solutions</a></Typography>
         </Grid>
 
           </Grid>
           </Container>
           </Box>
           </div>
           
         </footer>
         </Hidden>
       </div>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 