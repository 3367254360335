import React from 'react';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactsIcon from '@material-ui/icons/Contacts';

const useStyles = makeStyles({
  root: {
    width: 500,
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex:9,
    backgroundColor:'#123d59',
  },
});

export default function SimpleBottomNavigation() {
  const activemenu = typeof window !== 'undefined' ? window.location.pathname : '';
  const classes = useStyles();
  const [value, setValue] = React.useState(activemenu);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.stickToBottom}
    >
      <BottomNavigationAction style={{ color:'#fff' }} value="/products/" component={Link} to="/products/" label="Products" icon={<CardGiftcardIcon />} />
      <BottomNavigationAction style={{ color:'#fff' }} value="/solutions/" component={Link} to="/solutions/" label="Solutions" icon={<EmojiObjectsIcon />} />
      <BottomNavigationAction style={{ color:'#fff' }} value="/services/" component={Link} to="/services/" label="Services" icon={<SettingsIcon />} />
      <BottomNavigationAction style={{ color:'#fff' }} value="/contacts/" component={Link} to="/contacts/" label="Contacts" icon={<ContactsIcon />} />
    </BottomNavigation>
  );
}